<template>
  <div class="w_auto">
    <!-- <BreadcrumbNav></BreadcrumbNav> -->
    <div class="mt15">
      <div>
        <el-row :gutter="20">         
          <el-col :span="24">
            <div class="bagray bagrayns">
            <el-row :gutter="20">
              <el-col :span="6" v-for="(i, index) in xwzxList" :key="index" class="mt10">
                <el-card :body-style="{ padding: '0px' }" class="hovershadow is-always-shadow">
                  <div class="indpic">
                    <img v-if="i.meetingImg!=''" :src="i.meetingImg" alt="">
                    <img v-else src="@/assets/images/meeting/meeting.png" class="image" @click="subclick"/>
                    <div class="kcstat flex-betweens">
                      <span
                        ><i class="el-icon-video-camera-solid"></i
                        >{{ i.meetingStatus==0?'未开始':i.meetingStatus==1?"正在直播":'已结束' }}</span
                      >
                    </div>
                  </div>
                  <div style="padding: 14px">
                    <span class="bt-title" @click="subclick">{{ item.meetingDescribe }}</span>
                  </div>
                </el-card>
              </el-col>
            </el-row>
          </div>
          <div class="pagination">
            <el-pagination
              background
              layout="prev, pager, next"
              :page-size.sync="params.limit"
              :current-page="params.page"
              :total="count"
              @current-change="handleCurrentChange"
              >
            </el-pagination>
          </div>
          </el-col>                 
        </el-row>
      </div>
    </div>
   
  </div>
</template>

<script>
// import BreadcrumbNav from '@/components/breadCrumbs/Breadcrumb'
export default {
  // components:{ BreadcrumbNav },
  data(){
    return{
      token: localStorage.getItem("pc_token"),
      xwzxList:[],
      params: { limit: 12, page: 1 },
      count: 0,
    }
  },
  mounted(){
    this.queryMeetingRoomNoCourseListApi()
  },
  methods:{
    queryMeetingRoomNoCourseListApi(){
      this.$api.queryMeetingRoomNoCourseListApi({
        channelId: this.$http.channelId,
        page: this.params.page,
        limit:this.params.limit,
        token:this.token
      }).then(res => {
        if(res.code == 10000){
          this.xwzxList = res.data.data
          this.count = res.data.count
        }
      })
    },
    handleCurrentChange(curPage){
      this.params.page = curPage
      this.queryMeetingRoomNoCourseListApi()
    },
    subclick(){
      this.$router.push({
        path:'/activity/detail'
      })
    }
  }
  
}
</script>

<style scoped lang="scss">
.mt15 {
  margin-top: 15px;
  .bagray{
    padding: 10px 20px 15px; 
    background-color: #fafafa; 
    // border-radius: 5px;
    box-shadow: 0px 8px 10px -3px rgb(0 0 0 / 10%) 
  }
  .bagrayns{
    box-shadow:none
  }
  .indpic {
    position: relative;
    .image {
      width: 100%;
      display: block;
      cursor: pointer;
    }
    .kcstat {
      position: absolute;
      width: 100%;
      padding: 5px;
      bottom: 0;
      color: #fff;
      background: rgba(0, 0, 0, 0.2);
      font-size: 12px;
    }
    .kcstat i {
      font-size: 20px;
      color: #f00;
      margin-right: 5px;
    }   
  }
  .clearfix:before,
  .clearfix:after {
    display: table;
    content: "";
  }
  .clearfix:after {
    clear: both;
  }
  .flex-betweens{
      display: flex;
      justify-content : space-between;
      align-items: center; 
      align-content: center;
    }
  }
.pagination{
  display: flex;
  justify-content: flex-end;
  margin: 5% 0;
}
.hovershadow:hover {
    box-shadow: 0 0 5px 0 #c7c7c7, 0 1px 8px 0 #c7c7c7;
    transform: translateY(-3px);
  }
  .is-always-shadow {
    box-shadow: 0 2px 12px 0 rgb(0 0 0 / 10%);
  }
.bt-title{
  color: #333;
  font-size: 13px;
  margin-bottom: 10px;
  display: block;
  cursor: pointer;
}
.bt-title:hover{
  color: #3ce4bc;
}
.cktj{
  color:#000;
  font-size:12px
}
.btn-color ::v-deep .el-button--primary{
  background:linear-gradient(#3ce4bc 0%, #68b9cd 70%) !important;
}
.kc-input{
  width: 27%;
}
.clearfix ::v-deep .el-icon-caret-right {
  font-size: 25px;
}
.new-card::v-deep .el-card__header{
  padding: 10px 20px 15px; 
}
.new-card::v-deep .el-card__body {
  padding: 1px;
}
</style>